import './App.css';
import img from './img.jpg';

function App() {
  return (
    <div className="App">
      <div style={{ textAlign: 'center', fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>
        Hello, Welcome!
      </div>
      <div>
        This is Aayush.
      </div>
      <img src={img} alt="cat" style={{ width: '34%', display: 'block', margin: '0 auto' }} />
    </div>
  );
}

export default App;
